import { observer } from "mobx-react-lite";
import React from "react";
import { useAppModal } from "../app-modal";

export interface AppModalCloseProps {
    label?: string;
}

const AppModalClose = (props: AppModalCloseProps) => {
    const modal = useAppModal();

    return <button className="btn btn-outline-secondary" onClick={modal.close}>
        {props.label || "Закрыть"}
    </button>;
};

export default observer(AppModalClose);
