import { observer } from "mobx-react-lite";
import React from "react";
import { useAppModal } from "../app-modal";
import styles from "./app-modal-header.module.css";

export interface AppModalHeaderProps {
    title?: string;
    showCloseButton?: boolean;
}

const AppModalHeader = (props: AppModalHeaderProps) => {
    const modal = useAppModal();

    return <div className={styles.root}>
        <div className={styles.title}>
            {props.title || "Окно"}
        </div>

        {props.showCloseButton !== false &&
        <button className={styles.closeButton} onClick={modal.close}>
          <i className="fa fa-times"/>
        </button>
        }
    </div>;
};

export default observer(AppModalHeader);
