export enum DateFormattersTypes {
    t = "t",
    s = "s",
    d = "d",
    dt = "dt",
    exact = "exact",
}

const DateFormatters = {
    t: (locale: string) => Intl.DateTimeFormat(locale, {hour: "2-digit", minute: "2-digit", second: "2-digit"}),
    s: (locale: string) => Intl.DateTimeFormat(locale, {month: "short", year: "numeric"}),
    d: (locale: string) => Intl.DateTimeFormat(locale, {month: "short", day: "2-digit", year: "numeric"}),
    dt: (locale: string) => Intl.DateTimeFormat(locale, {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }),
    exact: (locale: string) => Intl.DateTimeFormat(locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }),
};

export function formatBy(locale: string, formatterType: DateFormattersTypes, value: string) {
    const formatter = DateFormatters[formatterType as DateFormattersTypes](locale);

    return formatter.format(new Date(value));
}

export default DateFormatters;
