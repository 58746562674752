import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../store";
import { useAsync } from "../../utils/hooks";
import { LoadingScreen } from "../../components/loading-screen";
import { UsersTable } from "./users-table";
import styles from "./users.module.scss";
import classNames from "classnames";
import { useDialogProvider } from "../../components/dialog-provider/dialog-provider";
import { RegisterUserDialog } from "./register-user-dialog";

const Users = () => {
    const store = useStore();
    const dialog = useDialogProvider();

    const content = useAsync({
        init: () => store.users.getUsers(0, 0),
        loading: () => <LoadingScreen/>,
        success: () => <UsersTable/>,
        failed: (e) => e.toString(),
    });

    const onRegisterUser = () => {
        dialog.show(RegisterUserDialog);
    };

    return <div className={classNames("d-flex flex-column h-100", styles.root)}>
        <div className="d-flex">
            <h1>Пользователи</h1>
        </div>

        <div className="mb-3">
            <span>Всего пользователей: {store.users.users.length}</span>

            <div>
                <button onClick={onRegisterUser} title={"Зарегистрировать пользователя"}>
                    <i className={"fa fa-plus"}/>
                </button>
            </div>
        </div>

        {content}
    </div>;
};

export default observer(Users);
