import { observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import styles from "./user-card.module.scss";
import { AppModal, AppModalHeader, AppModalBody } from "../../../../components/app-modal";
import { useStore } from "../../../../store";
import { DateColumn, DateTimeColumn, IsActiveColumn, IsExtensionColumn } from "../common";
import { optional } from "../../../../utils/table";
import classNames from "classnames";
import { AsyncButton } from "../../../../components/async-button";
import Noty from "noty";
import { withNoty } from "../../../../utils/with-noty";

interface UserCardProps {
    userId: string;
    close: () => void;
}

const Row = ({ label, children }: PropsWithChildren<{label: string}>) => {
    return <div className="row mt-1">
        <div className={classNames("col-4", styles.cell)}>
            {label}:
        </div>
        <div className={classNames("col-8", styles.cell)}>
            {children}
        </div>
    </div>;
};

const UserCard = ({ userId, close }: UserCardProps) => {
    const store = useStore();
    const user = store.users.users.find(u => u.userId === userId);

    if (!user) return null;

    const isMyProfile = userId === store.auth.user?.userId;
    const isNotEditable = isMyProfile || user.username === store.auth.NOBODY || user.username === store.auth.DELETED;

    const activate = async () => {
        if (!window.confirm(`Пользователь "${user.username}" будет АКТИВИРОВАН, выполнить?`)) return;

        await withNoty(
            () => store.users.activate(userId),
            "Пользователь активирован",
            "Не получилось активировать пользователя"
        );
    };

    const deactivate = async () => {
        if (!window.confirm(`Пользователь "${user.username}" будет ДЕАКТИВИРОВАН, выполнить?`)) return;

        await withNoty(
            () => store.users.deactivate(userId),
            "Пользователь деактивирован",
            "Не получилось деактивировать пользователя"
        );
    };

    const deleteUser = async () => {
        const msg = `Вы собираетесь удалить пользователя ${user.username}\n\n` +
        "Это действие невозможно отменить и оно будет иметь последствия для других пользователей!\n\n" +
        "Чтобы подтвердить действие введите email пользователя для удаления:";

        if (prompt(msg) !== user.username) {
            new Noty({
                text: "Пользователь не будет удален - ошибка подтверждения",
                type: "error"
            }).show();
            return;
        }

        await withNoty(
            () => store.users.delete(user.userId),
            "Пользователь безвозвратно удален",
            "Не получилось удалить пользователя"
        );
    };

    return <AppModal width={600} close={close}>
        <AppModalHeader title={`Пользователь ${user.username}`}/>

        <AppModalBody overrideClassName={styles.body}>
            <Row label="Id">
                <code>{user.userId}</code>
            </Row>

            <Row label="Тип">
                {IsExtensionColumn(user.isExtensionUser, ["Пользователь", "Плагин"])}
            </Row>

            <Row label="Email">
                <a href={`mailto:${user.username}`}>{user.username}</a>
                {isMyProfile && <span className="ml-1">(Вы)</span>}
            </Row>

            <Row label="Имя">
                {optional(user.name, () => user.name)}
            </Row>

            <Row label="Фамилия">
                {optional(user.surname, () => user.surname)}
            </Row>

            <Row label="День рождения">
                {DateColumn(user.birthday)}
            </Row>

            <hr/>

            <Row label="Активирован">
                {IsActiveColumn(user.activated, ["Не активирован", "Активирован"])}
            </Row>

            <Row label="Дата регистрации">
                {DateTimeColumn(user.registrationDate)}
            </Row>

            <Row label="Посл. активность">
                {DateTimeColumn(user.lastAccessed)}
            </Row>

            <Row label="TZ">
                <code>{user.timezone}</code>
            </Row>

            <div className="row mt-3">
                <div className="col-12">
                    {user.activated
                        ? <AsyncButton
                            className="btn btn-danger"
                            disabled={isNotEditable}
                            onClick={() => deactivate()}
                        >
                            Деактивировать
                        </AsyncButton>
                        : <AsyncButton
                            className="btn btn-warning"
                            disabled={isNotEditable}
                            onClick={() => activate()}
                        >
                            Активировать
                        </AsyncButton>
                    }

                    <AsyncButton
                        className="btn btn-danger ml-3"
                        disabled={isNotEditable}
                        onClick={() => deleteUser()}
                    >
                        Удалить пользователя
                    </AsyncButton>
                </div>
            </div>
        </AppModalBody>
    </AppModal>;
};

export default observer(UserCard);
