import { json } from "../utils/dataclass";

export class SubscriptionGroupDto {
    @json(String)
    public name: string;

    @json(String)
    public description: string;

    @json(Boolean)
    public userCanChange: boolean;

    @json(Boolean)
    public willBeNotified: boolean;

    constructor(name: string, description: string, userCanChange: boolean, willBeNotified: boolean) {
        this.name = name;
        this.description = description;
        this.userCanChange = userCanChange;
        this.willBeNotified = willBeNotified;
    }
}

export class SavedSearchQuery {
    @json(String)
    public id: string;

    @json(String)
    public query: string;

    @json(Number)
    public timestamp: number;

    @json(String)
    public title: string;

    constructor(id: string, query: string, timestamp: number, title: string) {
        this.id = id;
        this.query = query;
        this.timestamp = timestamp;
        this.title = title;
    }
}

export class UserDto {
    @json(String)
    public userId: string;

    @json(String)
    public username: string;

    @json(String, null)
    public name: string | null;

    @json(String, null)
    public surname: string | null;

    @json(String, null)
    public avatar: string | null;

    @json(Date, null)
    public registrationDate: Date | null;

    @json(Date, null)
    public birthday: Date | null;

    @json(Boolean)
    public isExtensionUser: boolean;

    @json(String, null)
    public timezone: string | null;

    @json(Date, null)
    public lastAccessed: Date | null;

    @json(Boolean)
    public activated: boolean;

    constructor(
        userId: string,
        username: string,
        name: string,
        surname: string,
        avatar: string | null,
        registrationDate: Date | null,
        birthday: Date | null,
        isExtensionUser: boolean = false,
        timezone: string | null,
        lastAccessed: Date | null,
        activated: boolean,
    ) {
        this.userId = userId;
        this.username = username;
        this.name = name;
        this.surname = surname;
        this.avatar = avatar;
        this.registrationDate = registrationDate;
        this.birthday = birthday;
        this.isExtensionUser = isExtensionUser;
        this.timezone = timezone;
        this.lastAccessed = lastAccessed;
        this.activated = activated;
    }
}

export class CurrentUserDto extends UserDto {
    @json([SubscriptionGroupDto])
    public subscriptionGroups: SubscriptionGroupDto[];

    @json(String)
    public kvSession: string;

    @json([SavedSearchQuery])
    public savedSearchQueries: SavedSearchQuery[];

    constructor(
        userId: string,
        username: string,
        name: string,
        surname: string,
        avatar: string | null,
        registrationDate: Date,
        birthday: Date | null,
        isExtensionUser: boolean,
        timezone: string | null,
        lastAccessed: Date | null,
        activated: boolean,
        subscriptionGroups: SubscriptionGroupDto[],
        kvSession: string,
        savedSearchQueries: SavedSearchQuery[],
    ) {
        super(
            userId, username, name, surname, avatar, registrationDate, birthday,
            isExtensionUser, timezone, lastAccessed, activated
        );
        this.subscriptionGroups = subscriptionGroups;
        this.kvSession = kvSession;
        this.savedSearchQueries = savedSearchQueries;
    }
}

