import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ApiError } from "./errors";

let sessionId = "";

export class BaseApi {
    protected axios: AxiosInstance;

    constructor(config?: AxiosRequestConfig) {
        this.axios = Axios.create(config);

        this.axios.interceptors.request.use(
            req => setSessionId(req),
            err => err,
        );

        this.axios.interceptors.response.use(
            resp => resp,
            err => wrapError(err),
        );
    }
}

function wrapError<R>(e: any): any {
    if (!e.response) throw e;

    const { data, status } = e.response;

    const message = typeof data === "object"
        ? data.entry
            ? `Backend error id: ${data.entry}`
            : data.message
        : data;

    throw new ApiError(message, data.code || 0, status || 0);
}

function setSessionId(req: AxiosRequestConfig): AxiosRequestConfig {
    if (sessionId === "") {
        generateSessionId();
    }

    return {
        ...req,
        headers: {
            ...req.headers,
            SessionId: sessionId,
        },
    };
}

export function generateSessionId() {
    sessionId = Math.round(Math.random() * 1E+10).toString();
    // tslint:disable-next-line:no-console
    console.info(`sessionId: ${sessionId}`);
}
