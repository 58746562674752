import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

export interface FormErrorProps {
    error: string;
}

const FormError = (props: FormErrorProps) => {
    const errorClasses = classNames({
        "form-group": true,
        "alert": true,
        "alert-danger": true,
        "d-none": !props.error,
    });

    return <div className={errorClasses}>
        <strong>Ошибка:</strong> {props.error}
    </div>;
};

export default observer(FormError);
