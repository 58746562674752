import { observer } from "mobx-react-lite";
import React from "react";
import styles from "./square-btn.module.scss";
import classNames from "classnames";

export interface SquareBtnProps {
    fa: string;
    className?: string;
    size?: number;
    onClick?: (e: React.MouseEvent) => void;
}

const SquareBtn = ({ fa, className, size = 32, onClick }: SquareBtnProps) => {
    return <button
        className={classNames("btn btn-outline-secondary", styles.squareBtn, className)}
        style={{
            width: size,
            height: size,
        }}
        onClick={onClick}
    >
        <i className={classNames("fa-fw", fa)}/>
    </button>;
};

export default observer(SquareBtn);
