import { observer } from "mobx-react-lite";
import React, { createContext, PropsWithChildren, useContext } from "react";
import ReactModal, { Styles } from "react-modal";
import styles from "./app-modal.module.scss";

export interface AppModalProps {
    width?: number;
    height?: number;

    // movable
    // minimize

    isOpen?: boolean;
    shouldFocusAfterRender?: boolean;
    shouldCloseOnOverlayClick?: boolean;
    shouldCloseOnEsc?: boolean;
    shouldReturnFocusAfterClose?: boolean;
    additionalStyles?: Styles;

    close(): void;
    onRequestClose?(): boolean;
    onAfterClose?(): void;
}

export interface AppModalState {
    close: () => void;
}

const AppModalContext = createContext<AppModalState | null>(null);

// для внутреннего использования в app-modal
export function useAppModal(): AppModalState {
    const state = useContext(AppModalContext);
    if (state === null) throw Error("AppModalContext not found");
    return state;
}

function defaultBool(value: boolean | undefined, defaultValue: boolean): boolean {
    return value === undefined ? defaultValue : value;
}

const AppModal = (props: PropsWithChildren<AppModalProps>) => {
    const close = () => {
        if (props.onRequestClose && !props.onRequestClose()) {
            return;
        }
        if (props.close) props.close();
    };

    const modalProps: ReactModal.Props = {
        isOpen: defaultBool(props.isOpen, true),
        shouldFocusAfterRender: defaultBool(props.shouldFocusAfterRender, true),
        shouldCloseOnOverlayClick: defaultBool(props.shouldCloseOnOverlayClick, true),
        shouldCloseOnEsc: defaultBool(props.shouldCloseOnEsc, true),
        shouldReturnFocusAfterClose: defaultBool(props.shouldReturnFocusAfterClose, true),
        onRequestClose: close,
        onAfterClose: props.onAfterClose,
        style: {
            overlay: {
                display: "flex",
            },
            content: {
                width: props.width,
                height: props.height,
            },
        },
    };

    const state: AppModalState = {
        close,
    };

    return <AppModalContext.Provider value={state}>
        <ReactModal {...modalProps} portalClassName={styles.root} className={styles.window}>
            {props.children}
        </ReactModal>
    </AppModalContext.Provider>;
};

export default observer(AppModal);
