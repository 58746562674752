import { observable } from "mobx";
import { UserDto } from "../api/users";
import { internalUsersApi, RegisterUserRequest } from "../api/internal-users";

export class UsersModule {
    @observable
    public users: UserDto[] = [];

    reset () {
        this.users = [];
    }

    async getUsers(page: number, size: number) {
        const { data } = await internalUsersApi.getUsers(page, size);
        this.users = data;
    }

    async activate(userId: string) {
        await internalUsersApi.activate(userId);
        this.setActivation(userId, true);
    }

    async deactivate(userId: string) {
        await internalUsersApi.deactivate(userId);
        this.setActivation(userId, false);
    }

    async delete(userId: string) {
        await internalUsersApi.delete(userId);
        const idx = this.users.findIndex(u => u.userId === userId);
        if (idx >= 0) this.users.splice(idx, 1);
    }

    async register(user: RegisterUserRequest) {
        await internalUsersApi.register(user).then(() => this.getUsers(0, 0));
    }

    private setActivation(userId: string, activation: boolean) {
        const user = this.users.find(u => u.userId === userId);
        if (!user) return;

        user.activated = activation;
    }
}
