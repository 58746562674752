import classNames from "classnames";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { getValidationsProperties } from "../form-helpers";
import { FormInputProps } from "../form-types";


function FormInput(props: FormInputProps) {
    const options = getValidationsProperties(props);
    const formRef = props.form.register(options);

    const inputClasses = classNames({
        "form-control": true,
        "is-invalid": props.form.errors[props.name] !== undefined,
    });

    return <div className={classNames("form-group", props.className)}>
        <label>
            {props.label}
        </label>
        <input
            type={props.type}
            className={inputClasses}
            autoComplete={props.autoComplete}
            name={props.name}
            placeholder={props.placeholder}
            ref={formRef}
        />
    </div>;
}

export default observer(FormInput);
