import Noty from "noty";
import { registerError, TARGET } from "../services/error-tracking";
import { clearHtml } from "./clear-html";

export async function withNoty<T>(block: () => Promise<T> | T, onSuccess: string | null, onError: string | null) {
    try {
        await block();
        if (onSuccess) {
            new Noty({
                type: "success",
                text: clearHtml(onSuccess),
            }).show();
        }
    } catch (e) {
        registerError(TARGET.REMOTE, e);

        const options: Noty.Options = e instanceof Error
            ? {
                type: "error",
                text: clearHtml(onError ? `${onError}. ${e.message}` : e.message),
            }
            : {
                type: "error",
                text: clearHtml(onError || "Произошла неизвестная ошибка"),
            };

        new Noty(options).show();
    }
}
