import { computed, observable } from "mobx";
import { authApi } from "../api/auth";
import { ApiError } from "../api/errors";
import { CurrentUserDto } from "../api/users";
import { Store } from "./index";
import { internalUsersApi } from "../api/internal-users";

export class AuthModule {
    // undefined - init
    // null - unauthorized
    // user - authorized
    @observable
    public user: CurrentUserDto | null | undefined = undefined;

    public NOBODY = "nobody";
    public DELETED = "deleted";

    constructor(private readonly store: Store) {
    }

    @computed
    get isNobody() {
        return this.user?.username === this.NOBODY;
    }

    public async ensureUserAuth() {
        if (this.user !== undefined) return;

        try {
            const publicUser = await authApi.currentUser();
            await internalUsersApi.getUser(publicUser.userId);
            this.user = publicUser;
        } catch (e) {
            if (e instanceof ApiError && (e.status === 401 || e.status === 403)) {
                this.user = null;
            } else {
                throw e;
            }
        }
    }
}
