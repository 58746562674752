import { observer, useLocalStore } from "mobx-react-lite";
import React, { ReactNode } from "react";

export interface AsyncButtonProps {
    className?: string;
    type?: "submit" | "reset" | "button";
    loading?: boolean;
    children?: ReactNode;
    label?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    showSpinnerOnly?: boolean;
    onClick?: (event: React.MouseEvent) => Promise<void>;
}

const AsyncButton = (props: AsyncButtonProps) => {
    const state = useLocalStore(() => ({
        loading: false,
    }));
    const onClick = props.onClick;

    const click = onClick && (async (event: React.MouseEvent) => {
        try {
            state.loading = true;
            await onClick(event);
        } finally {
            state.loading = false;
        }
    });

    return <button
        className={props.className}
        type={props.type}
        autoFocus={props.autoFocus}
        disabled={props.disabled || state.loading || props.loading}
        onClick={click}
    >
        <div className="mr-1">
            {(state.loading || props.loading) &&
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Загрузка...</span>
            </div>}
            {!(props.showSpinnerOnly && state.loading) && (props.label || props.children)}
        </div>
    </button>;
};

export default observer(AsyncButton);
