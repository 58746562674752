import React, { BaseSyntheticEvent, useState } from "react";
import { FormContextValues } from "react-hook-form/dist/contextTypes";
import { ValidationOptions } from "react-hook-form/dist/types";
import { ApiError } from "../../api/errors";
import { registerError, TARGET } from "../../services/error-tracking";
import { FormInputProps } from "./form-types";

// очистка props от лишних полей, беэ этого происходит ошибка "invalid input"
export function getValidationsProperties(props: FormInputProps): ValidationOptions {
    return {
        max: props.max,
        required: props.required,
        maxLength: props.maxLength,
        min: props.min,
        minLength: props.minLength,
        pattern: props.pattern,
        validate: props.validate,
    };
}

export class SubmitError extends Error {
}

export function useSubmitState<D>(
    form: FormContextValues<D>,
    callback: (data: D, event: BaseSyntheticEvent) => (Promise<void> | void),
): [string, (e: React.SyntheticEvent) => Promise<void>] {
    const [error, setError] = useState("");

    const submit = form.handleSubmit(async (data, event) => {
        try {
            setError("");
            await callback(data, event);
        } catch (e) {
            registerError(TARGET.REMOTE, e);
            if (e instanceof ApiError || e instanceof SubmitError) {
                setError(e.message);
            } else {
                setError("Произошла неизвестная ошибка");
            }
        }
    });

    return [error, submit];
}
