class ImgPaths {
    public static BROKEN_LINK = "/img/brokenlink.png";
}

export class Paths {
    public static HASH = "#";
    public static ROOT = "/";
    public static USERS = "/users";
    public static UNAUTHORIZED = '/unauthorized';

    public static img = ImgPaths;

    public static hash(): string {
        return this.HASH;
    }

    public static users() {
        return this.USERS;
    }

    public static unauthorized() {
        return this.UNAUTHORIZED;
    }
}

