import { fromJson } from "../utils/dataclass";
import { BaseApi } from "./base";
import { CurrentUserDto } from "./users";

export class AuthApi extends BaseApi {
    public async currentUser(): Promise<CurrentUserDto> {
        const {data} = await this.axios.get("/api/user");
        return fromJson(CurrentUserDto, data);
    }
}

export const authApi = new AuthApi();
