import { observable } from "mobx";

export type DataClassConstructor<T> = new (...fields: any[]) => T;

export interface PathItem {
    type: string;
    field: string;
}

export class JsonParseError extends Error {
    constructor(
        public readonly path: PathItem[],
        public readonly reason: string,
        public readonly literal: boolean = false,
    ) {
        super(`$.${path.map(p => `${p.type}[${p.field}]`).join(".")}: ${reason}`);
    }
}

export class JsonTypeError extends JsonParseError {
    constructor(value: any, name: string, literal: boolean = false) {
        super([], `value '${value}' is not ${name}`, literal);
    }
}

class TupleType {
    constructor(public readonly items: JsonTypes[]) {
    }
}

export function Tuple(...items: JsonTypes[]) {
    return new TupleType(items);
}

type PrimitiveJsonType =
    NumberConstructor |
    StringConstructor |
    BooleanConstructor;

type ValueJsonType =
    string |
    undefined |
    null;

export type JsonType =
    PrimitiveJsonType |
    ValueJsonType |
    DateConstructor |
    TupleType |
    Record<string, any> |
    DataClassConstructor<any>;

interface RecursiveArray<T> extends Array<T | RecursiveArray<T>> {
}

export type JsonTypes = JsonType | RecursiveArray<JsonType>;

export function json(...types: JsonTypes[]) {
    return (target: any, key: string, desc?: PropertyDescriptor) => {
        getProperties(target).push({
            type: typeToDesc(types),
            jsonKeys: [key, camelToSnakeCase(key)],
            classKey: key,
        });
        return observable(target, key, desc);
    };
}

export function fromJson<T>(constructor: DataClassConstructor<T>, value: any): T {
    const type = typeToDesc([constructor]);
    return type.fromJson(value);
}

export function fromJsonArray<T>(constructor: DataClassConstructor<T>, value: any): T[] {
    const type = typeToDesc([[constructor]]);
    return type.fromJson(value);
}

interface TypeDesc {
    name: string;
    fromJson(value: any): any;
}

interface PropertyDesc {
    type: TypeDesc;
    classKey: string;
    jsonKeys: string[];
}

const camelRegex = new RegExp("[A-Z]", "g");

function camelToSnakeCase(name: string): string {
    return name.replace(camelRegex, (v) => "_" + v.toLowerCase());
}

function getProperties(target: any): PropertyDesc[] {
    if (!target.hasOwnProperty("__properties__")) {
        const inheritedProps = target.__properties__ || [];
        target.__properties__ = [...inheritedProps];
    }
    return target.__properties__;
}

// instanceof не работает в тестах
function isJsonParseError(error: any): error is JsonParseError {
    if (error instanceof JsonParseError) return true;
    return Array.isArray(error.path) && typeof error.reason === "string";
}

const PRIMITIVES = [Number, String, Boolean];

const PRIMITIVES_COERCION: Record<string, Record<string, (name: string, value: any) => any>> = {
    string: {
        number: (name: string, value: string) => {
            const numValue = Number(value);
            if (isNaN(numValue)) throw new JsonTypeError(value, name);
            return numValue;
        },
    },
    number: {
        string: (name: string, value: number) => {
            return String(value);
        },
    },
    boolean: {
        string: (name: string, value: boolean) => {
            return String(value);
        },
    },
};

function typeToDesc(types: JsonTypes[]): TypeDesc {
    if (types.length === 0) throw new Error("Where must be at least one type");
    if (types.length > 1) return oneOfType(types);

    const type = types[0] as any;
    if (type === null) return valueType(null);
    if (type === undefined) return valueType(undefined);
    if (typeof type === "string") return valueType(type);
    if (PRIMITIVES.includes(type)) return primitiveType(type);
    if (type === Date) return dateType();
    if (type instanceof TupleType) return tupleType(type);
    if (Array.isArray(type)) return arrayType(type);
    if (type.prototype !== undefined) return dataClassType(type);
    return enumType(type); // todo test is enum
}

function oneOfType(types: JsonTypes[]): TypeDesc {
    const inner = types.map(t => typeToDesc([t]));
    const innerStr = inner
        .map(t => t.name)
        .join(" | ");

    return {
        name: `${innerStr}`,

        fromJson(value: any): any {
            const errors: Array<{desc: TypeDesc, error: JsonParseError}> = [];

            for (const desc of inner) {
                try {
                    return desc.fromJson(value);
                } catch (e) {
                    if (!isJsonParseError(e)) throw e;
                    errors.push({desc, error: e});
                }
            }

            const notLiteralError = errors.find(e => !e.error.literal);
            if (notLiteralError) throw notLiteralError.error;

            throw new JsonParseError([], "no matching pattern found");
        },
    };
}

function valueType(type: any): TypeDesc {
    let name: string = "";
    if (type === undefined || type === null) {
        name = String(type);
    } else if (typeof type === "string") {
        name = `'${type}'`;
    } else {
        throw new Error(`Unknown value type '${type}'`);
    }

    return {
        name,

        fromJson(value: any): any {
            if (value === type) return value;
            throw new JsonTypeError(value, this.name, true);
        },
    };
}

function primitiveType(type: PrimitiveJsonType): TypeDesc {
    const lowerName = type.name.toLowerCase();

    return {
        name: type.name,

        fromJson(value: any): any {
            const typeOf: string = typeof value;
            if (typeOf === lowerName) return value;

            const coercion = (PRIMITIVES_COERCION[typeOf] ?? {})[lowerName];
            if (coercion !== undefined) return coercion(this.name, value);

            throw new JsonTypeError(value, this.name);
        },
    };
}

function dateType(): TypeDesc {
    return {
        name: "Date",

        fromJson(value: any): any {
            const typeName = typeof value;
            if (typeName !== "string" && typeName !== "number") {
                throw new JsonTypeError(value, this.name);
            }
            const date = new Date(value) as any;
            if (date instanceof Date && !isNaN(date.getTime())) {
                return date;
            }
            throw new JsonTypeError(value, this.name);
        },
    };
}

function tupleType(type: TupleType): TypeDesc {
    const inner = type.items.map(t => typeToDesc([t]));
    const innerStr = inner
        .map(t => t.name)
        .join(", ");

    return {
        name: `Tuple<${innerStr}>`,

        fromJson(value: any): any {
            if (!Array.isArray(value)) {
                throw new JsonTypeError(value, this.name);
            }

            return inner.map((item, index) => {
                try {
                    return item.fromJson(value[index]);
                } catch (e) {
                    if (isJsonParseError(e)) {
                        const pathItem = {type: this.name, field: index.toString()};
                        throw new JsonParseError([pathItem, ...e.path], e.reason, e.literal);
                    } else {
                        throw e;
                    }
                }
            });
        },
    };
}

function arrayType(type: JsonTypes[]): TypeDesc {
    const inner = typeToDesc(type);

    return {
        name: `Array<${inner.name}>`,

        fromJson(value: any): any {
            if (!Array.isArray(value)) {
                throw new JsonTypeError(value, this.name);
            }

            return value.map((item, index) => {
                try {
                    return inner.fromJson(item);
                } catch (e) {
                    if (isJsonParseError(e)) {
                        const pathItem = {type: this.name, field: index.toString()};
                        throw new JsonParseError([pathItem, ...e.path], e.reason, e.literal);
                    } else {
                        throw e;
                    }
                }
            });
        },
    };
}

function getPropertyValue(obj: any, prop: PropertyDesc): any {
    let value: any;
    for (const key of prop.jsonKeys) {
        value = obj[key];
        if (value !== undefined) break;
    }
    return value;
}

function dataClassType(constructor: DataClassConstructor<any>): TypeDesc {
    const type = constructor.prototype;

    return {
        name: constructor.name,

        fromJson(value: any): any {
            if (value === null || typeof value !== "object") {
                throw new JsonTypeError(value, this.name);
            }

            const fields = getProperties(type).map(p => {
                try {
                    return p.type.fromJson(getPropertyValue(value, p));
                } catch (e) {
                    if (isJsonParseError(e)) {
                        const pathItem = {type: this.name, field: p.classKey};
                        throw new JsonParseError([pathItem, ...e.path], e.reason, e.literal);
                    } else {
                        throw e;
                    }
                }
            });
            return new constructor(...fields);
        },
    };
}

function enumType(type: Record<string, any>): TypeDesc {
    const values = Object.values(type);
    const valuesStr = values
        .map(v => `'${v}'`)
        .join(" | ");

    return {
        name: `${valuesStr}`,

        fromJson(value: any): any {
            if (!values.includes(value)) {
                throw new JsonTypeError(value, this.name);
            }
            return value;
        },
    };
}
