import BuildUrl from "build-url";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { CSSProperties } from "react";
import { UserDto } from "../../api/users";
import AppImg from "../app-img/app-img";
import styles from "./user-avatar.module.css";

// todo variable ?
const avatarService = "https://avatars.redforester.com";

export interface UserAvatarProps {
    user: UserDto;
    size?: number;
    className?: string;
    fallbackAvatarElement?: JSX.Element;
    styles?: CSSProperties;
    title?: string;
    onClick?(e: React.MouseEvent): void;
}

function buildAvatar(name: string, type = "png"): string {
    return BuildUrl(avatarService, {queryParams: {name, type}});
}

const UserAvatar = (
    {user, size, className, fallbackAvatarElement, styles: additionalStyles, title, onClick}: UserAvatarProps,
) => {
    let fallbackSrc: string | undefined;
    if (!fallbackAvatarElement) {
        fallbackSrc = (user.name && user.surname)
                ? buildAvatar(`${user.name} ${user.surname}`)
                : buildAvatar(user.username);
    }

    const classes = classNames(styles.userAvatar, className);

    const sizeStyle = size
        ? {width: size, height: size}
        : null;

    const inlineStyle: CSSProperties = {
        ...sizeStyle,
        ...additionalStyles,
    };

    return <AppImg
        className={classes}
        style={inlineStyle}
        src={user.avatar}
        fallbackSrc={fallbackSrc}
        unloader={fallbackAvatarElement}
        alt={user.username}
        title={title}
        onClick={onClick}
    />;
};

export default observer(UserAvatar);
