import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { AsyncButton } from "../../async-button";

export interface AppModalPrimaryProps {
    label?: string;
    className?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    loading?: boolean;
    onClick: (e: React.MouseEvent) => (Promise<any> | any);
}

const AppModalPrimary = (props: AppModalPrimaryProps) => {
    return <AsyncButton
        className={classNames("btn btn-primary", props.className)}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        loading={props.loading}
        onClick={props.onClick}
    >
        {props.label || "Применить"}
    </AsyncButton>;
};

export default observer(AppModalPrimary);
