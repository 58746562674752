import { observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import styles from "./app-modal-body.module.css";

export interface AppModalBody {
    overrideClassName?: string;
}

const AppModalBody = ({overrideClassName, children}: PropsWithChildren<AppModalBody>) => {
    return <div className={overrideClassName ? overrideClassName : styles.root}>
        {children}
    </div>;
};

export default observer(AppModalBody);
