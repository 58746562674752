import React from "react";

export function ellipsis(length: number) {
    return (value: string) => {
        if (value.length < length) return value;
        return value.slice(0, length) + '...';
    };
}

export function optional<T>(value: T | null | undefined, func: (value: T) => React.ReactNode) {
    return value
        ? func(value)
        : "-";
}
