import { observer } from "mobx-react-lite";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/bootstrap-v4.css";
import React, { useRef } from "react";
import { MenuItem } from "react-contextmenu";
import { cold, setConfig as setHotConfig } from "react-hot-loader";
import { hot } from "react-hot-loader/root";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import "./assets/bootstrap.min.css";
import "./assets/fa-min/css/fontawesome.min.css";
import "./assets/fa-min/css/regular.min.css";
import "./assets/fa-min/css/solid.min.css";
import "./assets/react-contextmenu.css";
import DialogProvider from "./components/dialog-provider/dialog-provider";
import LoadingScreen from "./components/loading-screen/loading-screen";
import { SecureRoute } from "./components/secure-route";
import { realUserGuard, unauthorizedUserGuard } from "./guards";
import { Paths } from "./paths";
import { Store, StoreContext } from "./store";
import { useAsync } from "./utils/hooks";
import { Users } from "./views/users";
import { Unauthorized } from "./views/unauthorized";

setHotConfig({
    // чтобы не читались данные с сервера при каждом hot-reload'е
    reloadHooks: false,
});

cold(MenuItem); // https://github.com/vkbansal/react-contextmenu/issues/298

Noty.overrideDefaults({
    theme: "bootstrap-v4",
    timeout: 5000,
});

function content(store: Store) {
    const realUser = realUserGuard(store);
    const unauthorizedUser = unauthorizedUserGuard(store);

    return <StoreContext.Provider value={store}>
        <DialogProvider>
            <BrowserRouter>
                <Switch>
                    <SecureRoute path={Paths.UNAUTHORIZED} guard={unauthorizedUser} component={Unauthorized}/>
                    <SecureRoute path={Paths.USERS} guard={realUser} component={Users}/>
                    <Redirect from={Paths.ROOT} to={Paths.users()}/>
                </Switch>
            </BrowserRouter>
        </DialogProvider>
    </StoreContext.Provider>;
}

function useInitStore() {
    const storeRef = useRef<Store>();
    let store: Store;

    // проверка на прототип - для hot-reload когда поменялись не компоненты а сам Store
    if (storeRef.current === undefined || Object.getPrototypeOf(storeRef.current) !== Store.prototype) {
        store = new Store();
        // для отладки в продакшене
        (window as any).debug = () => ({ store });
        storeRef.current = store;
    } else {
        store = storeRef.current;
    }
    return store;
}


const App = observer(() => {
    const store = useInitStore();

    return useAsync({
        dependencies: [store],
        init: async () => {
            await store.auth.ensureUserAuth();
        },
        loading: () => <LoadingScreen/>,
        failed: (e) => <div>{e.toString()}</div>,
        success: () => content(store),
    });
});

export default process.env.NODE_ENV === "development" ? hot(App) : App;
