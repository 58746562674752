import { observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import styles from "./app-modal-footer.module.css";

const AppModalFooter = (props: PropsWithChildren<{}>) => {
    return <div className={styles.root}>
        {props.children}
    </div>;
};

export default observer(AppModalFooter);
