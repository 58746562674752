export class ApiError extends Error {
    constructor(
        public readonly message: string,
        public readonly code: string,
        public readonly status: number,
    ) {
        super(message);
    }
}

// Ошибка, которая не должна произойти
export class SystemError extends Error {}
