import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import "ts-polyfill";
import App from "./app";
import "./index.css";
import * as serviceWorker from "./service-worker";
import 'mobx-react-lite/batchingForReactDom';

const root = document.getElementById("root");

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    root
);

// Необходимо для работы окон
ReactModal.setAppElement(root as HTMLElement);

// tslint:disable-next-line:no-console
console.info(`Build version: ${process.env.REACT_APP_VERSION}\nGit hash: ${process.env.REACT_APP_GIT_COMMIT_HASH}`);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
