import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { cloneElement, CSSProperties } from "react";
import { Img } from "react-image";
import { Paths } from "../../paths";

export interface AppImgProps {
    src: string | null | undefined;
    fallbackSrc?: string;
    unloader?: JSX.Element;
    alt: string;
    className?: string;
    style?: CSSProperties;
    title?: string;
    dataset?: { [key: string]: string };

    onClick?(e: React.MouseEvent): void;
}

const AppImg = (props: AppImgProps) => {
    const unloader = props.unloader ? cloneElement(
        props.unloader,
        {
            ...props,
            className: classNames(props.unloader.props.className, props.className),
        },
    ) : undefined;

    return <Img
        src={[props.src || "", props.fallbackSrc || Paths.img.BROKEN_LINK]}
        className={props.className}
        style={props.style}
        alt={props.alt}
        title={props.title}
        onClick={props.onClick}
        unloader={unloader}
    />;
};

export default observer(AppImg);
