/* eslint-disable */
import { DependencyList, MutableRefObject, ReactElement, useCallback, useContext, useEffect, useRef } from "react";
import { useAsync as useAsyncInner } from "react-async";
import { __RouterContext } from "react-router";
import { registerError, TARGET } from "../services/error-tracking";

export function useRouter() {
    return useContext(__RouterContext);
}

export interface UseAsyncOptions<R> {
    init: () => Promise<R>;
    loading: () => ReactElement;
    success: (result: R) => ReactElement;
    failed: (error: any) => ReactElement;
    dependencies?: DependencyList;
}

export function useAsync<R>(options: UseAsyncOptions<R>): ReactElement {
    const init = useCallback(options.init, options.dependencies || []);

    const { data, error, isLoading } = useAsyncInner({
        promiseFn: init,
    });

    if (isLoading) return options.loading();
    if (error !== undefined) {
        registerError(TARGET.REMOTE, error);
        return options.failed(error);
    }

    // проверить на undefined нельзя, потому что init может ничего не вернуть,
    // и это тоже должно считаться успешным завершением
    return options.success(data!);
}

// React не умеет в wheel
// https://github.com/bczFE/articles/issues/11
// https://github.com/facebook/react/issues/14856
export function useListenWheel(callback: (e: MouseWheelEvent) => void, ref: MutableRefObject<HTMLElement | null>) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    const callbackWrapper = useCallback((e) => callbackRef.current(e), []);

    useEffect(() => {
        const current = ref.current!;
        current.addEventListener("wheel", callbackWrapper);
        return () => current.removeEventListener("wheel", callbackWrapper);
    }, [ref]);
}

export function useWatch<T>(valueFn: () => T, callback: (newValue: T, oldValue: T) => void, immediately?: boolean) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    const oldValue = valueFn();
    if (immediately) {
        callbackRef.current(oldValue, oldValue);
    }
    useEffect(() => {
        return () => {
            const newValue = valueFn();
            if (newValue === oldValue) {
                return;
            }

            callbackRef.current(newValue, oldValue);
        };
    }, [oldValue]);
}
