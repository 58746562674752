import { BaseApi } from "./base";
import { fromJson } from "../utils/dataclass";
import { UserDto } from "./users";

export interface PaginationResponse {
    total: number;
    page: number | null;
    limit: number | null;
    data: any[];
}

export type UserLanguage = "en-US" | "ru";

export const LANGUAGES: Array<{id: UserLanguage, name: string}> = [
    {id: "en-US", name: "English"},
    {id: "ru", name: "Русский"},
];

export interface RegisterUserRequest {
    username: string;
    name: string;
    surname: string;
    language: UserLanguage;
}

export class InternalUsersApi extends BaseApi {
    async getUser(userId: string) {
        const { data } = await this.axios.get(`/api/internal/user?user_id=${userId}`);
        return fromJson(UserDto, data);
    }

    async getUsers(page: number, limit: number) {
        const { data: body } = await this.axios.get<PaginationResponse>('/api/internal/users');

        return {
            ...body,
            data: body.data.map(u => fromJson(UserDto, u))
        };
    }

    async activate(userId: string) {
        await this.axios.post(`/api/internal/users/${userId}/activation`);
    }

    async deactivate(userId: string) {
        await this.axios.delete(`/api/internal/users/${userId}/activation`);
    }

    async delete(userId: string) {
        await this.axios.delete(`/api/internal/user/${userId}/personal`);
    }

    async register(request: RegisterUserRequest) {
        await this.axios.post(`/api/internal/user/register`, request);
    }
}

export const internalUsersApi = new InternalUsersApi();
