import { observable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { createContext, createElement, FunctionComponent, PropsWithChildren, useContext, useRef } from "react";

export interface DialogProps<R = undefined> {
    close: (result?: R) => void;
}

export interface DialogState<R> {
    close(result?: R): void;
}

class DialogItem<T, R> implements DialogState<R> {
    constructor(
        private readonly component: FunctionComponent<T & DialogProps<R>>,
        private readonly props: T & DialogProps<R>,
    ) {
    }

    public render(key: number) {
        return createElement(this.component, {...this.props, key});
    }

    public close(result?: R): void {
        this.props.close(result);
    }
}

export class DialogProviderState {
    @observable
    private readonly stack: Array<DialogItem<any, any>> = [];

    public show<R = undefined>(
        component: FunctionComponent<DialogProps<R>>,
    ): Promise<R | undefined>;

    public show<T, R = undefined>(
        component: FunctionComponent<T & DialogProps<R>>,
        props: T,
    ): Promise<R | undefined>;

    public async show<T, R = undefined>(
        component: FunctionComponent<T & DialogProps<R>>,
        props?: T,
    ): Promise<R | undefined> {
        return new Promise((resolve) => {
            const dialogProps: T & DialogProps<R> = {
                close: (result?: R) => {
                    this.stack.pop();
                    resolve(result);
                },
                ...(props as any),
            };
            const item = new DialogItem(component, dialogProps);
            this.stack.push(item);
        });
    }

    public render() {
        return <div>
            {this.stack.map((d, i) => d.render(i))}
        </div>;
    }
}

export const DialogProviderContext = createContext<DialogProviderState | null>(null);

export function useDialogProvider(): DialogProviderState {
    const state = useContext(DialogProviderContext);
    if (!state) throw new Error("DialogProviderContext not found");
    return state;
}

const DialogProvider = ({children}: PropsWithChildren<{}>) => {
    const state = useRef<DialogProviderState>();
    if (state.current === undefined) {
        state.current = new DialogProviderState();
    }

    return <DialogProviderContext.Provider value={state.current}>
        {state.current.render()}
        {children}
    </DialogProviderContext.Provider>;
};

export default observer(DialogProvider);
