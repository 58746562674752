import { Paths } from "./paths";
import { Store } from "./store";

export function unauthorizedUserGuard(store: Store) {
    return async () => {
        await store.auth.ensureUserAuth();
        if (!store.auth.user || store.auth.isNobody) return;

        return Paths.ROOT;
    };
}

export function realUserGuard(store: Store) {
    return async () => {
        await store.auth.ensureUserAuth();
        if (store.auth.user && !store.auth.isNobody) return;

        return Paths.UNAUTHORIZED;
    };
}
