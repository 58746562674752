import { observable } from "mobx";
import { createContext, useContext } from "react";
import { AuthModule } from "./auth";
import { UsersModule } from "./users";

export class Store {
    public readonly auth: AuthModule;
    public readonly users: UsersModule;

    constructor() {
        this.auth = observable(new AuthModule(this));
        this.users = observable(new UsersModule());
    }

    public reset(): void {
        this.users.reset();
    }
}

export const StoreContext = createContext<Store | null>(null);

export function useStore(): Store {
    return useContext(StoreContext)!;
}
