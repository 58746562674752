import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import styles from "./users-table.module.scss";
import { useStore } from "../../../store";
import { Column, useTable, useSortBy, Row } from "react-table";
import { UserDto } from "../../../api/users";
import { UserAvatar } from "../../../components/user-avatar";
import { ellipsis, optional } from "../../../utils/table";
import classNames from "classnames";
import { SquareBtn } from "../../../components/square-btn";
import { useDialogProvider } from "../../../components/dialog-provider/dialog-provider";
import { UserCard } from "./user-card";
import { IsActiveColumn, DateColumn, DateTimeColumn, IsExtensionColumn } from "./common";


function compareDateTimeWithNullValues (rowA: Row<UserDto>, rowB: Row<UserDto>, columnId: string){

    const a = rowA.values[columnId] ?? new Date(0);
    const b = rowB.values[columnId] ?? new Date(0);

    return a-b;
}


const UsersTable = () => {
    const store = useStore();
    const dialog = useDialogProvider();
    const { users } = store.users;

    const show = (userId: string) => {
        dialog.show(UserCard, {userId});
    };




    const columns: Array<Column<UserDto>> = useMemo(() => [
        {
            Header: "",
            accessor: "avatar",
            Cell: c => <UserAvatar user={c.row.original} size={32}/>,
        },
        {
            Header: "Email",
            accessor: "username",
            Cell: c => <a href={`mailto:${c.value}`}>{ellipsis(40)(c.value)}</a>,
        },
        {
            Header: "Имя",
            accessor: "name",
            Cell: c => optional(c.value, ellipsis(40)),
        },
        {
            Header: "Фамилия",
            accessor: "surname",
            Cell: c => optional(c.value, ellipsis(40)),
        },
        {
            Header: "Акт.",
            accessor: "activated",
            Cell: c => IsActiveColumn(c.value),
            sortType: "basic",
        },
        {
            Header: "Регистрация",
            accessor: "registrationDate",
            Cell: c => DateColumn(c.value, "small"),
            sortType: "datetime",
        },
        {
            Header: "Последння акт.",
            accessor: "lastAccessed",
            Cell: c => DateTimeColumn(c.value, "small"),
            sortType: compareDateTimeWithNullValues,
        },
        {
            Header: "Тип",
            accessor: "isExtensionUser",
            Cell: c => IsExtensionColumn(c.value),
            sortType: "basic",
        },
    ], []);

    const table = useTable({
        columns,
        data: users,
        getRowId: u => u.userId
    },
    useSortBy
    );

    return <div className="overflow-auto">
        <table
            {...table.getTableProps()}
            className={classNames("table table-hover table-sm", styles.table)}
        >
            <thead>
            {table.headerGroups.map(headerGroup => <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                     {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>)}
                <th/>
            </tr>)}
            </thead>

            <tbody {...table.getTableBodyProps()}>
            {table.rows.map(row => {
                table.prepareRow(row);
                return <tr {...row.getRowProps()}>
                    {row.cells.map(cell => <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                    </td>)}
                    <td>
                        <SquareBtn
                            fa="fas fa-eye"
                            onClick={() => show(row.original.userId)}
                        />
                    </td>
                </tr>;
            })}
            </tbody>
        </table>
    </div>;
};

export default observer(UsersTable);
