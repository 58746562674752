import DateFormatters from "../../../utils/date-formatters";
import { optional } from "../../../utils/table";
import React from "react";

const d = DateFormatters.d("ru-RU");
const dt = DateFormatters.dt("ru-RU");

export type TextChoice = [JSX.Element | string, JSX.Element | string];

export const IsExtensionColumn = (value: boolean, text: TextChoice = ["Польз.", "Плагин"]) => value
    ? <span className="text-danger">{text[1]}</span>
    : <span className="text-success">{text[0]}</span>;

export const IsActiveColumn = (value: boolean, text: TextChoice = [<b key="1">-</b>, <b key="2">+</b>]) => value
    ? <span className="text-success">{text[1]}</span>
    : <span className="text-danger">{text[0]}</span>;

export const DateColumn = (value: Date | null, className?: string) =>
    optional(value, v => <span className={className}>{d.format(v)}</span>);

export const DateTimeColumn = (value: Date | null, className?: string) =>
    optional(value, v => <span className={className}>{dt.format(v)}</span>);
