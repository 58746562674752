import { AppModal, AppModalBody, AppModalHeader, AppModalFooter, AppModalClose, AppModalPrimary } from "../../../components/app-modal";
import styles from "./register-user-dialog.module.scss";
import React from "react";
import useForm from "react-hook-form";
import { observer } from "mobx-react-lite";
import { FormInput } from "../../../components/form-components/form-input";
import { DialogProps } from "../../../components/dialog-provider/dialog-provider";
import { useSubmitState } from "../../../components/form-components/form-helpers";
import { FormError } from "../../../components/form-components/form-error";
import { useStore } from "../../../store";
import { withNoty } from "../../../utils/with-noty";
import { FormSelect } from "../../../components/form-components/form-select";
import { LANGUAGES, UserLanguage } from "../../../api/internal-users";

interface InviteUserState {
    username: string;
    name: string;
    surname: string;
    language: UserLanguage;
}

type RegisterUserDialogProps = DialogProps;

const RegisterUserDialog = ({ close }: RegisterUserDialogProps) => {
    const store = useStore();
    const form = useForm<InviteUserState>();
    const [error, submit] = useSubmitState(form, async (data) => {
        await withNoty(
            async () => {
                await store.users.register(data);
                close();
            },
            "Пользователь зарегистрирован",
            "Не получилось зарегистрировать пользователя"
        );
    });

    return <AppModal width={600} close={close}>
        <AppModalHeader title={`Зарегистрировать пользователя`}/>

        <AppModalBody overrideClassName={styles.body}>
            <div className="form">
                <FormInput
                    label={"email"}
                    placeholder={"email"}
                    name={"username"}
                    form={form}
                    required={true}
                    pattern={/.+@.+/}
                />

                <FormInput
                    label={"name"}
                    placeholder={"name"}
                    name={"name"}
                    form={form}
                    required={true}
                />

                <FormInput
                    label={"surname"}
                    placeholder={"surname"}
                    name={"surname"}
                    form={form}
                    required={true}
                />

                <FormSelect
                    label={"language"}
                    placeholder={"language"}
                    name={"language"}
                    options={LANGUAGES}
                    form={form}
                    required={true}
                />

                <FormError error={error}/>
            </div>
        </AppModalBody>

        <AppModalFooter>
            <AppModalClose/>
            <AppModalPrimary onClick={submit} label={"Зарегистрировать"}/>
        </AppModalFooter>
    </AppModal>;
};

export default observer(RegisterUserDialog);
