import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styles from "./loading-screen.module.css";

const LOADING_SHOW_DELAY = 500;

export interface LoadingScreenProps {
    className?: string;
}

const LoadingScreen = ({ className }: LoadingScreenProps) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setShow(true), LOADING_SHOW_DELAY);
        return () => clearTimeout(timer);
    }, []);

    const rootClasses = classNames(
        className,
        {
            [styles.root]: true,
            invisible: !show,
        },
    );

    return <div className={rootClasses}>
        <div className={"spinner-border spinner-border"} role="status">
            <span className="sr-only">Загрузка...</span>
        </div>
    </div>;
};

export default observer(LoadingScreen);
