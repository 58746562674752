import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { getValidationsProperties } from "../form-helpers";
import { FormElementProps } from "../form-types";

interface FormSelectProps extends FormElementProps {
    options: Array<{ id: string, name: string }>;
}

const FormSelect = (props: FormSelectProps) => {
    const options = getValidationsProperties(props);
    const formRef = props.form.register(options);

    const inputClasses = classNames({
        "form-control": true,
        "is-invalid": props.form.errors[props.name] !== undefined,
    });

    return <div className={classNames("form-group", props.className)}>
        <label>
            {props.label}
        </label>
        <select
            className={inputClasses}
            name={props.name}
            placeholder={props.placeholder}
            ref={formRef}
        >
            {props.options.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
        </select>
    </div>;
};

export default observer(FormSelect);
